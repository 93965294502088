/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { contactUsPost } from '../fn/api/contact-us-post';
import { ContactUsPost$Params } from '../fn/api/contact-us-post';
import { joinWaitlistPost } from '../fn/api/join-waitlist-post';
import { JoinWaitlistPost$Params } from '../fn/api/join-waitlist-post';
import { stayInLoopPost } from '../fn/api/stay-in-loop-post';
import { StayInLoopPost$Params } from '../fn/api/stay-in-loop-post';

@Injectable({ providedIn: 'root' })
export class ApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `contactUsPost()` */
  static readonly ContactUsPostPath = '/contact-us';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactUsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactUsPost$Response(params: ContactUsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return contactUsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactUsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactUsPost(params: ContactUsPost$Params, context?: HttpContext): Observable<void> {
    return this.contactUsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `joinWaitlistPost()` */
  static readonly JoinWaitlistPostPath = '/join-waitlist';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `joinWaitlistPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  joinWaitlistPost$Response(params: JoinWaitlistPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return joinWaitlistPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `joinWaitlistPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  joinWaitlistPost(params: JoinWaitlistPost$Params, context?: HttpContext): Observable<void> {
    return this.joinWaitlistPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `stayInLoopPost()` */
  static readonly StayInLoopPostPath = '/stay-in-loop';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stayInLoopPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  stayInLoopPost$Response(params: StayInLoopPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return stayInLoopPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stayInLoopPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  stayInLoopPost(params: StayInLoopPost$Params, context?: HttpContext): Observable<void> {
    return this.stayInLoopPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}

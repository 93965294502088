import { AbstractControl, ValidationErrors, Validators } from "@angular/forms";

export class CustomValidators {
  static email(control: AbstractControl): ValidationErrors | null {
    if (
      control.value === null ||
      typeof control.value !== "string" ||
      control.value === ""
    ) {
      return null;
    }

    const emailError = Validators.email(control);

    const pattern = /^[^\s@]+@[^\s@]+\.[a-z]{2,}$/i;
    const isPatternValid = pattern.test(control.value);

    if (!isPatternValid || emailError) {
      return {
        email: { value: control.value },
      };
    }

    return null;
  }
}
